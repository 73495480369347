import MuiButton from "@material-ui/core/Button"
import { type Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import ExpandMore from "@material-ui/icons/ExpandMore"
import classNames from "classnames"
import * as csx from "csx"

import type { PropsWithChildren } from "react"
import { Glyph } from "../symbols"
import BaseInput, { type BaseInputProps } from "./BaseInput"

const styles = ({ palette }: Theme) =>
  createStyles({
    button: {
      boxShadow: "none",
      padding: "0.25rem 1rem",
      borderRadius: "4rem",
      border: "3px solid",
      color: Button.theme.colors.buttonFontColor.toString(),
      backgroundColor: Button.theme.colors.buttonColor.toString(),
      textDecoration: "none",
      background: `-webkit-gradient(linear,left top,left bottom,from(${Button.theme.colors.buttonColor.toString()}),to(${Button.theme.colors.buttonColor.toString()}))padding-box,-webkit-gradient(linear,left top,right top,from(#ea9f88),color-stop(20%, #ffe0d4),color-stop(40%, #f8f6e3),color-stop(60%, #b8cbc6),color-stop(80%, #7996a1),to(#537d81)) border-box;background: linear-gradient(#${Button.theme.colors.buttonColor.toString()}, ${Button.theme.colors.buttonColor.toString()}) padding-box,linear-gradient(90deg,#ea9f88 0%,#ffe0d4 20%,#f8f6e3 40%,#b8cbc6 60%,#7996a1 80%,#537d81 100%)border-box`,
      transition: "border-color 0.2s",
      "&:hover": {
        borderColor: "transparent",
        boxShadow: "none"
      },
      "&:focus": {
        borderColor: Button.theme.colors.selectionColor.toString(),
        boxShadow: "none"
      }
    },
    actionButton: {
      padding: csx.important("0.25rem 20px"),
      border: "none",
      background: "transparent"
    },
    iconButton: {
      width: "2.5rem",
      height: "2.5rem"
    },
    small: {
      paddingTop: "0.2em"
    },
    label: {
      fontSize: "15px",
      fontWeight: 500
    },
    disabled: {
      color: csx.important(Button.theme.colors.disabled.toString()),
      borderColor: csx.important(Button.theme.colors.disabled.toString()),
      border: csx.important("3px solid")
    },
    transparentDisabled: {
      color: csx.important(Button.theme.colors.disabled.toString()),
      backgroundColor: csx.important("transparent"),
      borderColor: csx.important(Button.theme.colors.disabled.toString())
    },
    normalCase: {
      textTransform: "initial"
    },
    noPadding: {
      padding: 0,
      verticalAlign: "baseline"
    },
    margin: {
      margin: "0.5rem",
      marginRight: "0.75rem",
      marginLeft: "0.75rem"
    },
    wide: {
      minWidth: "13rem"
    },
    narrow: {
      minWidth: "1rem"
    },
    outlined: {
      backgroundColor: "transparent",
      borderColor: palette.divider,
      borderWidth: "1.5px"
    },
    glyph: {
      fontSize: "16px",
      fontWeight: "bold"
    },
    glyphMargin: {
      marginLeft: "15px"
    },
    "@media (max-width: 480px)": {
      wide: {
        minWidth: "auto"
      }
    },
    hidden: {
      visibility: "hidden"
    }
  })

export type ButtonVariant = "action" | "expandMore" | "icon" | "primary" | "secondary"

interface ButtonProps extends BaseInputProps {
  ariaControls?: string
  ariaSelected?: boolean
  color?: "primary" | "secondary" | "default" | "inherit"
  context?: any
  disabled?: boolean
  fullWidth?: boolean
  hidden?: boolean
  icon?: string
  margin?: boolean
  noIcon?: boolean
  noPadding?: boolean
  normalCase?: boolean
  onClick?: (context, event) => void
  role?: string
  size?: "small" | "medium" | "large"
  type?: "button" | "reset" | "submit"
  variant?: ButtonVariant
}

class Button extends BaseInput<PropsWithChildren<ButtonProps> & WithStyles<typeof styles>, {}> {
  get componentName() {
    return ["ui", "form", "Button"]
  }

  static defaultProps: ButtonProps = {
    color: "secondary",
    variant: "primary"
  }

  render() {
    const {
      ariaControls,
      ariaSelected,
      children,
      classes,
      className,
      color,
      context,
      disabled,
      fullWidth,
      hidden,
      margin,
      normalCase,
      noPadding,
      role,
      size,
      type,
      variant
    } = this.props
    const muiVariant = this.getMuiVariant()
    const narrow = muiVariant === "text" || ["expandMore", "icon"].includes(variant ?? "")
    const style = muiVariant === "text" ? { backgroundColor: "transparent" } : {}

    return (
      <MuiButton
        aria-controls={ariaControls}
        aria-selected={ariaSelected}
        role={role}
        type={type}
        fullWidth={fullWidth}
        variant={muiVariant}
        disabled={disabled}
        color={color}
        className={classNames(
          classes.button,
          variant === "icon" ? classes.iconButton : classes.button,
          variant === "action" && classes.actionButton,
          narrow ? classes.narrow : classes.wide,
          (normalCase || variant === "action") && classes.normalCase,
          noPadding && classes.noPadding,
          margin && classes.margin,
          hidden && classes.hidden,
          className
        )}
        style={style}
        size={size}
        classes={{
          label: variant !== "expandMore" ? classes.label : undefined,
          outlined: classes.outlined,
          disabled: variant === "action" ? classes.transparentDisabled : classes.disabled
        }}
        onClick={this.onClick}
        data-context={context}
      >
        {children}
        {this.renderIcon()}
      </MuiButton>
    )
  }

  private renderIcon() {
    const { variant, noIcon, classes, icon } = this.props

    if (noIcon === true) return

    if (icon) {
      return <Glyph glyphClass={classes.glyph} glyphType="materialize" glyph={icon} />
    }

    switch (variant) {
      case "action":
        return (
          <Glyph
            glyphClass={classNames(classes.glyph, classes.glyphMargin)}
            glyphType="materialize"
            glyph="arrow_forward"
          />
        )
      case "expandMore":
        return <ExpandMore />
    }
  }

  private getMuiVariant() {
    const { variant, disabled } = this.props
    // MUI variants: "text" | "flat" | "outlined" | "contained" | "raised" | "fab" | "extendedFab"
    switch (variant) {
      case "icon":
      case "secondary":
        return "outlined"
      case "primary":
        return disabled ? "outlined" : "contained"
      case "expandMore":
        return "contained"
      case "action":
        return "text"
    }
  }

  private onClick = event => this.props.onClick?.(this.props.context, event)
}

export default withStyles(styles)(Button)
