import { ColorHelper, color } from "csx"
import { BaseModuleWithAppName } from "../../controller/Module"
import { HintsaThemeConstants as ThemeConstants } from "./HintsaThemeConstants"

export interface Theme {
  colors: Colors
  font: Font
  images: Images
  baseClasses: BaseClasses
  background: string
}

export interface Colors {
  report: {
    positive: ColorHelper
    neutral: ColorHelper
    negative: ColorHelper
    report_excellent: ColorHelper
    report_well_done: ColorHelper
    report_neutral: ColorHelper
    report_need_attention: ColorHelper
    report_need_extra_focus: ColorHelper
    report_benchmark: ColorHelper
  }
  common: {
    disabledText: ColorHelper
    disabledBackground: ColorHelper
  }
  hintsa: {
    ivory: ColorHelper
    charcoal: ColorHelper
    alabaster: ColorHelper
    granite: ColorHelper
    tangerine: ColorHelper
  }
  primary: ColorHelper
  secondary: ColorHelper
  tertiary: ColorHelper
  primaryContrast: ColorHelper
  secondaryContrast: ColorHelper
  tertiaryContrast: ColorHelper
  baseGray: ColorHelper
  disabled: ColorHelper
  faintColor: ColorHelper
  okColor: ColorHelper
  errorColor: ColorHelper
  warningColor: ColorHelper
  // Legacy
  backgroundColor: ColorHelper
  windowColor: ColorHelper
  windowControlBackgroundColor: ColorHelper
  fontColor: ColorHelper
  accentColor1: ColorHelper
  accentColor2: ColorHelper
  accentColor3: ColorHelper
  emphasizeColor1: ColorHelper
  emphasizeColor2: ColorHelper
  emphasizeColor3: ColorHelper
  fontLightColor: ColorHelper
  positiveColor: ColorHelper
  negativeColor: ColorHelper
  deEmphasizedColor: ColorHelper
  veryFaintColor: ColorHelper
  menuColor: ColorHelper
  menuFontColor: ColorHelper
  menuSelectedFontColor: ColorHelper
  buttonColor: ColorHelper
  buttonFontColor: ColorHelper
  buttonMouseOverColor: ColorHelper
  buttonMouseOverBorderColor: ColorHelper
  buttonColorAlt: ColorHelper
  buttonFontColorAlt: ColorHelper
  buttonMouseOverColorAlt: ColorHelper
  buttonFontColorDisabled: ColorHelper
  selectionColor: ColorHelper
  selectionFontColor: ColorHelper
  inputBackgroundColor: ColorHelper
  inputBorderColor: ColorHelper
  dialogHeaderBackgroundColor: ColorHelper
}

export interface Font {
  fontFamily: string
  fontSize: string
  menuFontCasing: "uppercase" | "lowercase" | "initial"
}

export interface Images {
  header: string
  headerClass: string
  title: string
  titleClass: string
  background: string
}

// Use for classes that would otherwise be duplicated across components
export interface BaseClasses {
  menuClass: string
  menuItemClass: string
}

export abstract class ThemeModule extends BaseModuleWithAppName implements Theme {
  protected abstract _colors: Colors
  protected abstract _font: Font
  protected abstract _images: Images
  protected abstract _baseClasses: BaseClasses

  declare themeConstants: ThemeConstants

  get moduleName() {
    return "Theme"
  }

  get dependencies() {
    return ["ThemeConstants"]
  }

  get colors(): Colors {
    return this._colors
  }

  get font(): Font {
    return this._font
  }

  get images(): Images {
    return this._images
  }

  get baseClasses(): BaseClasses {
    return this._baseClasses
  }

  get background() {
    return this.themeConstants.background
  }
}

export abstract class CoreThemeColors implements Colors {
  get primary(): ColorHelper {
    return this.themeConstants.primaryColor
  }
  get secondary(): ColorHelper {
    return this.themeConstants.secondaryColor
  }
  get tertiary(): ColorHelper {
    return this.themeConstants.tertiaryColor
  }
  get primaryContrast(): ColorHelper {
    return this.themeConstants.white
  }
  get secondaryContrast(): ColorHelper {
    return this.themeConstants.white
  }
  get tertiaryContrast(): ColorHelper {
    return this.themeConstants.white
  }
  get baseGray(): ColorHelper {
    return this.themeConstants.baseGray
  }
  get disabled(): ColorHelper {
    return this.themeConstants.disabledColor
  }
  get faintColor(): ColorHelper {
    return this.themeConstants.faintColor
  }
  get backgroundColor(): ColorHelper {
    return this.themeConstants.backgroundColor
  }
  get windowColor(): ColorHelper {
    return color("#fff")
  }
  get windowControlBackgroundColor(): ColorHelper {
    return color("#fbfbfb")
  }
  get fontColor(): ColorHelper {
    return this.themeConstants.fontColor
  }
  get accentColor1(): ColorHelper {
    return color("#f37b89")
  }
  get accentColor2(): ColorHelper {
    return color("#84d2df")
  }
  get accentColor3(): ColorHelper {
    return color("#ffe17f")
  }
  get emphasizeColor1(): ColorHelper {
    return color("#de6328")
  }
  get emphasizeColor2(): ColorHelper {
    return color("#3a8da9")
  }
  get emphasizeColor3(): ColorHelper {
    return color("#3a8da9")
  }
  get fontLightColor(): ColorHelper {
    return this.themeConstants.ivoryColor
  }
  get positiveColor(): ColorHelper {
    return this.themeConstants.positiveColor
  }
  get negativeColor(): ColorHelper {
    return this.themeConstants.negativeColor
  }
  get neutralColor(): ColorHelper {
    return this.themeConstants.neutralColor
  }
  get deEmphasizedColor(): ColorHelper {
    return color("#eaecee").darken("35%")
  }
  get veryFaintColor(): ColorHelper {
    return this.themeConstants.oatmealColor
  }
  get menuColor(): ColorHelper {
    return this.primary
  }
  get menuFontColor(): ColorHelper {
    return this.themeConstants.menuFontColor
  }
  get menuSelectedFontColor(): ColorHelper {
    return this.themeConstants.menuSelectedFontColor
  }
  get buttonColor(): ColorHelper {
    return this.themeConstants.ivoryColor
  }
  get buttonFontColor(): ColorHelper {
    return this.themeConstants.charcoalColor
  }
  get buttonMouseOverColor(): ColorHelper {
    return this.buttonColor.darken("15%")
  }
  get buttonMouseOverBorderColor(): ColorHelper {
    return color("#00ff00")
  }
  get buttonColorAlt(): ColorHelper {
    return this.accentColor1
  }
  get buttonFontColorAlt(): ColorHelper {
    return color("#ffffff")
  }
  get buttonMouseOverColorAlt(): ColorHelper {
    return this.buttonColorAlt.darken("15%")
  }
  get inputBackgroundColor(): ColorHelper {
    return this.themeConstants.inputBackgroundColor
  }
  get inputBorderColor(): ColorHelper {
    return this.themeConstants.primaryColor
  }
  get okColor(): ColorHelper {
    return this.themeConstants.positiveColor
  }
  get buttonFontColorDisabled(): ColorHelper {
    return this.faintColor
  }
  get warningColor(): ColorHelper {
    return this.themeConstants.neutralColor
  }
  get errorColor(): ColorHelper {
    return this.themeConstants.negativeColor
  }
  get selectionColor(): ColorHelper {
    return this.themeConstants.cobaltColor.lighten(0.15)
  }
  get selectionFontColor(): ColorHelper {
    return this.fontLightColor
  }

  get dialogHeaderBackgroundColor(): ColorHelper {
    return this.themeConstants.cobaltColor
  }

  themeConstants: ThemeConstants
  report
  common
  hintsa: {
    ivory: ColorHelper
    charcoal: ColorHelper
    alabaster: ColorHelper
    tangerine: ColorHelper
    granite: ColorHelper
  }

  constructor(themeConstants: ThemeConstants) {
    this.report = {
      positive: themeConstants.positiveColor,
      neutral: themeConstants.neutralColor,
      negative: themeConstants.negativeColor,
      report_excellent: themeConstants.report_excellent,
      report_well_done: themeConstants.report_well_done,
      report_neutral: themeConstants.report_neutral,
      report_need_attention: themeConstants.report_need_attention,
      report_need_extra_focus: themeConstants.report_need_extra_focus,
      report_benchmark: themeConstants.report_benchmark
    }
    this.common = {
      disabledText: themeConstants.disabledTextColor,
      disabledBackground: themeConstants.disabledBackgroundColor
    }
    this.hintsa = {
      ivory: themeConstants.ivoryColor,
      charcoal: themeConstants.charcoalColor,
      alabaster: themeConstants.alabasterColor,
      tangerine: themeConstants.tangerineColor,
      granite: themeConstants.graniteColor
    }

    this.themeConstants = themeConstants
  }
}

export abstract class CoreThemeFont implements Font {
  get fontFamily(): string {
    return "'Söhne', sans-serif"
  }
  get fontSize(): string {
    return "16px"
  }
  get menuFontCasing() {
    return "initial" as const
  }
}

export abstract class CoreThemeImages implements Images {
  get header(): string {
    return ""
  }
  get headerClass(): string {
    return ""
  }
  get title(): string {
    return ""
  }
  get titleClass(): string {
    return ""
  }
  get background(): string {
    return ""
  }
  get placeholderCampaign(): string {
    return ""
  }
}

export abstract class CoreThemeBaseClasses implements BaseClasses {
  get menuClass(): string {
    return ""
  }
  get menuItemClass(): string {
    return ""
  }
}
