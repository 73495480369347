import { ModelManager } from "core/modules/state/model/ModelManager"
import { Store } from "core/modules/store/Store"
import { RethrowExceptions } from "lib/utils/ErrorUtils"
import ReactGA from "react-ga4"
import shortid from "shortid"

import { BaseModule } from "../../controller/Module"
import { OauthApi } from "../api/OauthApi"
import { AccessToken, AuthenticationState } from "../state/model/Model"

export interface AuthenticationActions {
  loginWithToken(token: string, email: string, rememberMe: boolean, mfaToken?: string): Promise<Response>
  loginWithSurveyCode(companyCode: string, surveyCode: string): Promise<Response>
  logout(): Promise<boolean>
  resetTokenLoginState(): void
}

interface LoginParameters {
  token?: string
  mfaToken?: string
  email?: string
  companyCode?: string
  surveyCode?: string
  rememberMe?: boolean
}

export class AuthenticationActionsModule extends BaseModule implements AuthenticationActions {
  declare modelManager: ModelManager
  declare oauthApi: OauthApi
  declare store: Store

  get moduleName() {
    return "AuthenticationActions"
  }

  get dependencies() {
    return ["Store", "ModelManager", "OauthApi"]
  }

  @RethrowExceptions
  async loginWithToken(token: string, email: string, rememberMe: boolean, mfaToken?: string): Promise<Response> {
    this.logger.info("Login with token. Remember me:", rememberMe)
    return await this.login({ token, email, rememberMe, mfaToken })
  }

  @RethrowExceptions
  async loginWithSurveyCode(companyCode: string, surveyCode: string): Promise<Response> {
    this.logger.info("Login with company and survey code", { companyCode, surveyCode })
    return await this.login({ companyCode, surveyCode })
  }

  @RethrowExceptions
  async login(loginParameters: LoginParameters): Promise<Response> {
    const { token, mfaToken, email, companyCode, surveyCode, rememberMe } = loginParameters

    ReactGA.event({
      category: "Login",
      action: "Logging in with token"
    })

    const response = token
      ? await this.oauthApi.loginWithToken(token, email ?? "", mfaToken ?? "")
      : await this.oauthApi.loginWithSurveyCode(companyCode ?? "", surveyCode ?? "")

    if (response.ok) {
      const json = await response.json()

      json.id = shortid.generate()

      ReactGA.event({
        category: "Login",
        action: "Login successful with token"
      })

      const accessToken = await this.coreActions.importDocument<AccessToken>(json, "AccessToken")
      this.oauthApi.accessToken = accessToken

      if (rememberMe) {
        this.logger.info("Remembering user, saving access token", {
          scope: accessToken.scope,
          tokenType: accessToken.token_type
        })
        localStorage.setItem("authentication_access_token", accessToken.access_token)
        localStorage.setItem("authentication_token_scope", accessToken.scope)
        localStorage.setItem("authentication_token_type", accessToken.token_type)
      }

      const authenticationState = Object.assign(
        {},
        this.coreActions.getDefaultDocumentLocal<AuthenticationState>("AuthenticationState")
      )

      authenticationState.loggedIn = true
      authenticationState.tokenLoginError = undefined
      this.coreActions.setDefaultDocumentLocal(authenticationState)

      return response
    } else {
      ReactGA.event({
        category: "Login",
        action: "Logging in failed with token"
      })

      const json = await response.json()
      throw this.oauthApi.getHttpError(response.status, json)
    }
  }

  async logout() {
    this.logger.info("Logging out")

    localStorage.clear()

    ReactGA.event({
      category: "Login",
      action: "Logging out"
    })

    const response = await this.oauthApi.logout()

    if (response.ok) {
      this.logger.info("Logged out from remote system")
      return true
    } else {
      this.logger.warning("Logging out from remote system failed.")
      return false
    }
  }

  resetTokenLoginState() {
    const authenticationState = this.coreActions.getDefaultDocumentLocal<AuthenticationState>("AuthenticationState")
    authenticationState.loggedIn = false
    authenticationState.tokenLoginError = undefined
    this.coreActions.setDefaultDocumentLocal(authenticationState)

    if (this.oauthApi.accessToken) {
      this.coreActions.removeDocumentLocal(this.oauthApi.accessToken)
    }
  }
}
