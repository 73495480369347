import CircularProgress from "@material-ui/core/CircularProgress"
import { createStyles, withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import * as csx from "csx"
import { style } from "typestyle"

import { ViewComponentProps } from "core/components/base/ViewComponent"

import { ViewComponent } from "../base/ViewComponent"

interface SpinnerProps extends ViewComponentProps {
  color?: string | csx.ColorHelper
  className?: string
  progress?: number
  maxProgress?: number
  classes?: any
}
const containerSize = "10rem"
const styles = () =>
  createStyles({
    colorPrimary: {
      color: Spinner.theme.colors.secondary.toString()
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: containerSize,
      height: containerSize,
      fontSize: "20px",
      position: "relative"
    }
  })

class Spinner extends ViewComponent<SpinnerProps, {}> {
  get componentName(): string[] {
    return ["ui", "Spinner"]
  }

  render() {
    super.render()

    const { classes } = this.props
    let { color } = this.props

    if (color && typeof color !== "string") color = (color as csx.ColorHelper).toHexString()

    return (
      <div className={classNames(classes.container)}>
        {this.renderProgress(color as string)}
        {this.renderSpinner(color as string)}
      </div>
    )
  }

  private renderSpinner(color: string) {
    const { classes } = this.props
    if (this.props.maxProgress > 0 && this.props.progress >= this.props.maxProgress) {
      const completedClass = style({
        width: "80px",
        height: "80px",
        textAlign: "center",
        border: `4px solid ${color}`,
        borderRadius: "80px"
      })
      return <div className={completedClass} />
    }

    let colorStyle = {}
    if (color) {
      colorStyle = {
        color: color
      }
    }

    return <CircularProgress size={80} classes={{ colorPrimary: classes.colorPrimary }} style={colorStyle} />
  }
  private renderProgress(color: string) {
    let progress
    if (this.props.progress !== undefined && this.props.maxProgress !== undefined) {
      progress = this.props.progress / this.props.maxProgress
      progress = Math.min(Math.floor(progress * 100), 100)
    }

    if (progress === undefined) return <div />

    const progressClass = style({
      color,
      position: "absolute",
      top: 0,
      left: 0,
      textAlign: "center",
      width: "100%",
      lineHeight: "10rem"
    })

    // Don't show 100% (checkmark instead)
    if (progress === 100) {
      progress = "✓"
    } else {
      progress = `${progress}%`
    }

    return <div className={progressClass}>{progress}</div>
  }
}

export default withStyles(styles)(Spinner as any)
